
.animate-custom-bounce {
    animation: custom-bounce 4s 1;
  }	
  
  @keyframes custom-bounce {
    0% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    10% {
        transform: translateY(-28%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    20% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    30% {
        transform: translateY(-24%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    40% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    50% {
        transform: translateY(-20%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    60% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    70% {
        transform: translateY(-16%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    80% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    90% {
        transform: translateY(-6%);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  .search-user-btn {
    position: absolute;
    bottom: 1.75rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .app-background {
    max-height: calc(100% - 4.5rem);
  }

  .friends-wrapper {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
    max-height: 100%;
    margin: 0 auto;
  }
  .friends-wrapper.hide {
    display: none;
  }

  @media only screen and (min-width: 600px) {
    .friends-wrapper {
      border-radius: 0.5rem;
      max-height: 90%;
      margin: auto;
    }
  }

  .title-display {
    position: relative;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
  .title-display .top-banner {
    position: absolute;
    top: -100%;
    transition: 0.5s;
  }
  .title-display .bottom-banner {
    position: relative;
    bottom: 0;
    transition: 0.5s;
  }
  .title-display.next-banner .top-banner {
    top: 0;
  }
  .title-display.next-banner .bottom-banner {
    bottom: -100%;
  }