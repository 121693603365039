.instruct-lang-wrapper {
    display: flex;
    padding: 0.5rem 0;
    width: calc(100% + 1rem);
    justify-content: flex-start;
    align-content: center;
    flex-grow: 1;
    gap: 0.5rem;
    flex-wrap: nowrap;
}

.instruct-lang-option {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius);
    font-weight: 800;
    font-size: 0.8rem;
    @apply bg-neutral-300;
    color: var(--black);
    height: fit-content;
    align-self: center;
    width: fit-content;
    white-space: nowrap;
    transition: 0.2s;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
}
.instruct-lang-option:first-child {
    margin-left: auto;
}
.instruct-lang-option:only-child {
    margin-left: auto;
    margin-right: auto;
}
.instruct-lang-option:last-child {
    margin-right: auto;
}

.instruct-lang-option.active {
    background: var(--white);
}
.instruct-audio-wrapper {
    position: relative;
    display: flex;
    padding-bottom: 0.5rem;
    justify-content: space-around;
    align-content: center;
}
.instruct-audio {
    opacity: 0.5;
    transform: scale(1.2);
    transition: 0.3s;
    cursor: pointer;
}
.instruct-audio.active {
    opacity: 1;
    transform: scale(1.5);
    filter: invert(1);
}
.instruct-audio-wrapper .mic-holder {
    position: relative;
    width: 3rem;
    height: 3rem;
}
.instruct-audio-wrapper .mic-holder .pin {
    position: absolute;
    left: 0;
    top: 0;
}

.instruct-audio-wrapper .auto-send {
    position: absolute;
    left: calc(-100%);
    padding-right: 1.5rem;
    cursor: pointer;
    border-radius: var(--large-radius) 0 0 var(--large-radius);
}
.instruct-audio-wrapper .auto-send.active {
    color: var(--black);
    @apply bg-amber-400;
    box-shadow: var(--inner-shadow);
}