
.bucket-form .btn {
    margin-bottom: -1rem;
}

.bucket-header-emoji-1 {
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
    left: 0rem;
    bottom: -0.5rem;
    letter-spacing: -0.2em;
    transform: rotate(-30deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-2 {
    position: relative;
    display: inline-block;
    left: -0.4rem;
    bottom: -0.2rem;
    letter-spacing: -0.2em;
    transform: rotate(-20deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-3 {
    position: relative;
    display: inline-block;
    left: -0.8rem;
    bottom: 0;
    letter-spacing: -0.2em;
    transform: rotate(-10deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-4 {
    position: relative;
    display: inline-block;
    left: -1.2rem;
    bottom: 0.1rem;
    letter-spacing: -0.2em;
    transform: rotate(0deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-5 {
    position: relative;
    display: inline-block;
    left: -1.6rem;
    bottom: 0;
    letter-spacing: -0.2em;
    transform: rotate(10deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-6 {
    position: relative;
    display: inline-block;
    left: -2rem;
    bottom: -0.2rem;
    letter-spacing: -0.2em;
    transform: rotate(20deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}
.bucket-header-emoji-7 {
    position: relative;
    display: inline-block;
    left: -2.4rem;
    bottom: -0.5rem;
    letter-spacing: -0.2em;
    transform: rotate(30deg);
    transition: 0.6s;
    animation: shuffle 1s 1;
}

.bucket-animation-wrapper {
    margin-top: -0.75rem;
}

@keyframes shuffle {
    0% {
        transform: rotate(0deg);
        bottom:0
    }
    40% {
        transform: rotate(0deg);
        bottom:1rem;
    }
    50% {
        transform: rotate(0deg);
        bottom: 0;
    }
    90% {
        transform: rotate(0deg);
        bottom:0.75rem;
    }
    100% {
        transform: rotate(0deg);
        bottom: 0;
    }
}

.bucket-columns {
    position: relative;
    width: 110%;
    margin-left: -5%;
    border-radius: var(--large-radius);
}

.column {
    border-radius: var(--large-radius);
    min-height: 180px;
    max-height: 20vh;
    padding: 0.5rem;
}
.second-column {
    @apply bg-neutral-200;
}
.column-entry {
    border-radius: var(--radius);
    width: 100%;
    padding: 0.5rem 1rem;
    @apply bg-slate-300;
}
.column-entry p {
    font-size: 12px;
    font-weight: 400;
}
.column-picked-entry {
    border-radius: var(--radius);
    width: 100%;
    font-size: 12px;
    padding: 0.5rem 1rem;
    background: var(--white);
}
.column-picked-entry p {
    font-size: 12px;
    font-weight: 400;
}