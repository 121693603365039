select, input:not(input[type=checkbox]) {
    width: 160px;
    min-width: 60px;
}

select.wide, input.wide:not(input[type=checkbox]) {
    width: 240px;
}

.signup-form .profile-pic, .guest-form .profile-pic {
    border-radius: 50%;
    opacity: 0.6;
    @apply h-36 w-36;
}
.signup-form .profile-pic-holder, .guest-form .profile-pic-holder {
    margin-top: -5rem;
    border-radius: 50%;
    opacity: 1;
    background: var(--white);
    @apply h-36 w-36;
}
.signup-form .profile-pic.has-file, .guest-form .profile-pic.has-file {
    opacity: 1;
}

.signup-form .change-photo-btn, .guest-form .change-photo-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 0;
    padding: 0 0.75rem;
    margin-top: -1rem;
}

