.chatroom-header .info {
    font-weight: bold;
    font-size: 1rem;
    border: 2px solid var(--black);
    border-radius: 50%;
    height: 1.25rem;
    width: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
}

.chatroom-header .info-wrapper {
    position: relative;
    overflow: visible;
    height: 1.25rem;
    width: 1.25rem;
}
.chatroom-header .info-wrapper .pin {
    top: -0.25rem;
    left: -0.25rem;
}

.chatroom-header .close-holder {
    position: absolute;
    top: -0.75rem;
    right: 0rem;
}

.animate-hop {
    animation: hop 4.5s 3;
}

@keyframes hop {
    0% {
        transform: translateY(0) rotateY(0deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    2% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    13% {
        transform: translateY(-45%) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    25% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    48% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    52% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    65% {
        transform: translateY(-45%) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    78% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    100% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
}

.chatroom-container {
    padding: 1rem;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

.panel-connector {
    position: absolute;
    width: calc(100% - 2rem - 2px);
    height: 7rem;
    box-sizing: border-box;
    top: -8rem;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    @apply bg-slate-700;
}
.panel-connector.show {
    top: 0;
}