

.user-card {
    background: var(--white);
    border-radius: var(--large-radius);
    padding: 1.5rem 1rem 1.75rem 1rem;
    box-shadow: var(--subtle-outer-shadow);
    min-width: 240px;
    /* width: 100%;
    max-width: 100%; */
    max-width: 240px;
}

.profile-holder {
    margin-top: -5rem;
}

.user-card .p-emphasize {
    line-height: 0.1em;    
}

.guest-badge {
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0 1rem;
    border-radius: var(--radius);
    @apply bg-emerald-700;
    color: var(--white-text);
    margin-top: -0.5rem;
}
.ban-badge {
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0 1rem;
    border-radius: var(--radius);
    @apply bg-red-700;
    color: var(--white-text);
    margin-top: -0.5rem;
}

.btn-wrapper {
    background: var(--black);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.interpreter-info p:first-child {
    font-weight: 600;
}
.interpreter-info p:last-child {
    font-style: italic;
}
.interpreter-info {
    margin: 0.5rem 0;
}
.edit-element select {
    width: auto;
    min-width: fit-content;
}

.edit-element .warning {
    color: var(--white-text);
    @apply bg-red-700;
    border-radius: var(--radius);
    padding: 0.5rem 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
}
.edit-element .warning:active {
    color: var(--white-text);
    @apply bg-red-200 text-red-800
}
.edit-holder {
    width: 100%;
    overflow: hidden;
}
.edit-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 200%;
    transition: 0.3s;
}
.edit-icons {
    transition: 0.3s;
    width: 50%;
}
.edit-form {
    transition: 0.3s;
    width: 50%;
}
.edit-wrapper .shift {
    margin-left: -50%;
}

.interpreter-info {
    text-align: center;
}

.request-tag {
    font-weight: 500;
    @apply bg-sky-800;
    color: var(--white-text);
    padding: 0.5rem 1rem;
    border-radius: var(--radius);
}

.friend-flag {
    position: absolute;
    left: -1.5rem;
    top: 0;
    opacity: 0.5;
}

.card-add-friend-btn {
    position: absolute;
    bottom: -0.25rem;
}
.card-add-friend-btn.pending {
    background: var(--black);
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}