

.scenario-form .btn {
    margin-bottom: -1rem;
}
.scenario-header-emoji-1 {
    position: relative;
    display: inline-block;
    margin-left: 0.25rem;
    left: 0;
    bottom: 0;
    letter-spacing: -0.2em;
    line-height: 0;
    animation: shake-box 0.5s 1;
    animation-delay: 1.5s;
    transition: 0.6s;
    z-index: 2;
}
.scenario-header-emoji-2 {
    position: relative;
    display: inline-block;
    left: 0;
    bottom: 0;
    line-height: 0;
    letter-spacing: -0.2em;
    animation: shake-box 0.5s 1;
    animation-delay: 3s;
    transition: 0.6s;
    z-index: 1;
}
.scenario-header-emoji-3 {
    position: relative;
    display: inline-block;
    bottom: 0;
    line-height: 0;
    letter-spacing: -0.2em;
    animation: shake-box 0.5s 1;
    animation-delay: 1s;
    transition: 0.6s;
    z-index: 2;
}
.scenario-header-emoji-4 {
    position: relative;
    display: inline-block;
    left: -2.5rem;
    bottom: -0.6rem;
    letter-spacing: -0.2em;
    animation: shake-box 0.5s 1;
    animation-delay: 0;
    transition: 0.6s;
    z-index: 5;
}
.scenario-header-emoji-5 {
    position: relative;
    display: inline-block;
    left: -2.5rem;
    bottom: -0.6rem;
    letter-spacing: -0.2em;
    animation: shake-box 0.5s 1;
    animation-delay: 5s;
    transition: 0.6s;
    z-index: 3;
}

.scenario-animation-wrapper {
    margin-top: -0.5rem;
}

@keyframes shake-box {
    0% {
        transform: rotate(0deg) skew(0deg);
    }
    10%, 40%, 70% {
        transform: rotate(20deg) skew(10deg);
    }
    25%, 55%, 85% {
        transform: rotate(-20deg) skew(-10deg);
    }
    100% {
        transform: rotate(0deg) skew(0deg);
    }
}

.scenario-columns {
    position: relative;
    width: 110%;
    margin-left: -5%;
    border-radius: var(--large-radius);
}

.column {
    border-radius: var(--large-radius);
    min-height: 180px;
    max-height: 20vh;
    padding: 0.5rem;
}
.second-column {
    @apply bg-neutral-200;
}
.column-entry {
    border-radius: var(--radius);
    width: 100%;
    padding: 0.5rem 1rem;
    @apply bg-slate-300;
}
.column-entry p {
    font-size: 12px;
    font-weight: 400;
}
.column-picked-entry {
    border-radius: var(--radius);
    width: 100%;
    font-size: 12px;
    padding: 0.5rem 1rem;
    background: var(--white);
}
.column-picked-entry p {
    font-size: 12px;
    font-weight: 400;
}