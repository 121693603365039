
.speechToText-btn {
    border-radius: var(--radius);
    min-width: 5em;
    transition: 0.3s;
}
.speechToText-btn .pin {
    @apply invert
}
.speechToText-btn .pin.absolute {
    position: absolute;
}

.pin.mic-active {
    @apply bg-red-700;
}
.pin.mic-active img {
    filter: invert(1);
}
