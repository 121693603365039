
.loading-icon {
    height: 2rem;
    width: 2rem;
    border-radius: var(--radius);
    transition: background 1s;
    @apply animate-spin
  }

  .loading-icon.large {
    height: 3rem;
    width: 3rem;
  }
  .loading-icon.medium {
    height: 1.5rem;
    width: 1.5rem;
  }
  .loading-icon.small {
    height: 1rem;
    width: 1rem;
    border-radius: var(--mini-radius);
  }
  .loading-icon.mini {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: var(--mini-radius);
  }