.chat-entry {
    position: relative;
    width: fit-content;
    display: flex;
}
.chat-entry.sender {
    margin-left: auto;
}

.chat-entry-content {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    border-radius: var(--radius);
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    color: var(--white);
    @apply bg-blue-600;
}

.chat-entry .message-sender {
    margin-top: 0.15rem;
    font-size: 10px;
    font-weight: 500;
}
.chat-content-wrapper {
    width: fit-content;
    max-width: 80%;
}


/* .chat-entry.sender {
    margin-left: auto;
} */
.chat-entry.sender .chat-entry-content {
    color: var(--black);
    @apply bg-slate-200;
}
.twoWay-guest .chat-entry-content {
    color: var(--white);
    @apply bg-emerald-700;
}
.message-interpreter .chat-entry-content {
    color: var(--black);
    @apply bg-yellow-400;
}
.chat-content-wrapper .msg-timestamp {
    font-size: 10px;
}
.chat-content-wrapper.sender .msg-timestamp {
    text-align: end;
}
.speak-message {
    opacity: 0.3;
}
.speak-message:active {
    opacity: 0.6;
}
.speak-message.active {
    opacity: 0.8;
}

.large-speaker.animate-custom-pulse {
    transition: 0.5s;
    opacity: 1;
    animation-name: custom-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
  
@keyframes custom-pulse {
    0% {
        opacity: 0.4;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    20% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    40% {
        opacity: 0.4;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    60% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    80% {
        opacity: 0.4;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.response-status {
    position: absolute;
    font-weight: 800;
    white-space: nowrap;
    right: 0.5rem;
    top: -0.65rem;
    @apply bg-amber-400;
    color: var(--black);
    padding: 0 0.25rem;
    border-radius: var(--mini-radius);
    box-shadow: 0 0.15rem rgba(0,0,0,0.1);
}
.response-status.complete {
    display: flex;
    height: 15px;
    align-items: center;
    @apply bg-green-700;
    color: var(--white);
}

.response-status .blip {
    position: relative;
    font-size: 10px;
    font-weight: 800;
    border-radius: var(--mini-radius);
    @apply bg-amber-400 text-amber-400;
    @apply animate-ping;
}
.response-status .blip.no-animation {
    animation: none;
}
.response-status p {
    position: absolute;
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.response-status.complete p {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
}
.response-info {
    position: absolute;
    right: -0.4rem;
    top: -0.65rem;
    font-size: 10px;
    padding: 0 0.25rem;
    border-radius: var(--mini-radius);
    width: fit-content;
    color: var(--white);
    @apply bg-sky-500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% + 0.6rem);
    box-shadow: 0 0.15rem rgba(0,0,0,0.1);
}
.sender .response-info {
    background: var(--gray);
}

.response-context {
    font-style: italic;
    font-size: 1.55rem;
    font-weight: 600;
}

.response-choice-wrapper {
    max-height: 33vh;
    padding: 0.5rem 0;
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.response-choice {
    @apply bg-slate-300;
    font-weight: 500;
    border-radius: var(--radius);
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.2s;
}
.response-choice:hover {
    @apply bg-slate-200;
}

.center-modal.choice {
    padding: 1.5rem 1rem 1rem 1rem;
}
.center-modal.scale {
    padding: 1.5rem 1rem 1rem 1rem;
}
.cancel-response {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
}

.twoWay-guest-status {
    position: absolute;
    right: -0.4rem;
    top: -0.65rem;
    font-size: 10px;
    padding: 0 0.25rem;
    border-radius: var(--mini-radius);
    width: fit-content;
    color: var(--black);
    @apply bg-slate-200;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% + 0.6rem);
    box-shadow: 0 0.15rem rgba(0,0,0,0.1);
}
.interpreter-status {
    position: absolute;
    right: -0.4rem;
    top: -0.65rem;
    font-size: 10px;
    font-weight: 600;
    padding: 0 0.25rem;
    border-radius: var(--mini-radius);
    width: fit-content;
    color: var(--black);
    @apply bg-amber-500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% + 0.6rem);
    box-shadow: 0 0.15rem rgba(0,0,0,0.1);
}



.center-modal.correction {
    min-width: 300px;
    padding: 1.5rem 1.5rem 0 1.5rem;
}
.center-modal.correction input {
    width: 100%;
}
.correct-modal-close {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
}
.correct-header {
    display: flex;
    gap: 0.25rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
}
.correct-header p {
    margin-bottom: 0.1rem;
}
.correct-content {
    text-align: center;
    border-radius: var(--radius);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2em;
    padding: 1rem;
    color: var(--white-text);
    @apply bg-blue-600;
}
.correct-translation {
    display: flex;
    justify-content: center;
    border-radius: var(--radius);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2em;
    padding: 1rem;
    color: var(--black);
    @apply bg-amber-400;
}
.correct-translation div {
    width: fit-content;
}
.correct-translation p {
    width: 100%;
    font-size: 1rem;
}
.correct-timestamp {
    font-size: 0.6rem;
    text-align: end;
    white-space: nowrap;
    overflow: visible;
}
.correct-modal-btn {
    margin-bottom: -1rem;
}
.correct-flag {
    position: absolute;
    top: -1rem;
    left: -0.25rem;
    font-size: 1.5rem;
    transform: rotate(-10deg);
}
.sender .correct-flag {
    top: -1rem;
    left: 1rem;
}

.correct .chat-content-wrapper {
    width: 90%;
    max-width: 90%;
}
.correct .chat-entry {
    width: 100%;
}
.correct .chat-entry-content {
    width: 100%;
}

.correct-comment-wrapper {
    min-height: 2rem;
    height: fit-content;
    width: calc(100% + 2.75rem);
    margin-left: -2.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    color: var(--black);
    @apply bg-yellow-400;
}
.correct-comment-title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 10px;
    font-weight: 600;
}
.correct-comment-content {
    font-size: 0.8rem;
    font-weight: 400;
}
.center-modal.correct-notification {
    min-width: 300px;
}

/* INTERPRETER STUFF */
.interpreter:not(.sender) .chat-content-wrapper {
    width: 100%;
    max-width: 100%;
}

.interpreter .chat-entry:not(.sender) {
    width: 100%;
}
.interpreter .chat-entry:not(.sender) .chat-entry-content {
    width: 100%;
    border-radius: var(--large-radius) var(--radius) var(--radius) var(--radius);
    padding-right: 0.25rem;
}
.interpreter .chat-entry:not(.sender) .message-content {
    padding-right: 0.25rem;
}
.interpreter .message-translate-wrapper {
    min-height: 2rem;
    height: fit-content;
    width: calc(100% + 2.5rem);
    margin-left: -2.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    color: var(--black);
    @apply bg-yellow-400;
}
.message-translate-wrapper .message-translate-title {
    font-size: 10px;
    font-weight: 600;
}

.message-content-img {
    max-height: 8rem;
    max-width: 11rem;
    height: auto;
    width: auto;
    border-radius: var(--radius);
}

.img-fullscreen-wrapper {
    padding: 5vh 5vw;
}
.img-fullscreen-wrapper img {
    height: auto;
    width: auto;
}

.reveal-wrapper {
    opacity: 0.3;
    transition: 0.2s;
}
.reveal-wrapper.show {
    opacity: 0.8;
}