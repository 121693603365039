
.join-form-options .btn {
    font-size: 0.8rem;
    min-width: 90px;
    padding: 0 1rem;
}
.screen-title {
    color: var(--white);
    height: fit-content;
    margin: 0 auto 0.5rem auto;
    width: fit-content;
    min-width: 200px;
    max-width: 80%;
    margin-left: 10%;
    text-align: start;
    filter: drop-shadow(0 0 0.3rem rgba(0,0,0,0.8));
    cursor: pointer;
}
.screen-title {
    position: absolute;
    left: -1rem;
    top: 1rem;
}
.screen-title h2 {
    line-height: 0.8em;
}
.login-form-modal {
    margin: 0 auto;
}