
.tutorial-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    padding: 1rem;
    /* @apply bg-gradient-to-b from-white via-white to-transparent; */
    background: rgba(255,255,255,0);
    background-image: linear-gradient(var(--white), var(--white), rgba(255,255,255,0));
    transition: 1s;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tutorial-wrapper.expand {
    height: 150vh;
    background-image: linear-gradient(var(--white), var(--white), var(--white), rgba(255,255,255,0));
}
.tutorial-wrapper.no-transparency {
    background: var(--white);
    background-image: linear-gradient(var(--white), var(--white), var(--white), var(--white));
    height: 100vh;
}
.tutorial-wrapper.behind-menu {
    z-index: 1;
}




.tutorial-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.tutorial-wrapper h1 {
    transform: translateY(-50vh);
    transition: 0.5s;
}
.tutorial-wrapper.expand h1 {
    transform: none;
}
.tutorial-wrapper.no-transparency h1 {
    transform: none;
}


.tutorial-nav-btn {
    align-self: center;
    opacity: 0;
    transition: 0.2s;
}
.no-transparency .tutorial-nav-btn {
    opacity: 1;
}

.tutorial-content {
    opacity: 0;
    align-self: center;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.no-transparency .tutorial-content {
    opacity: 1;
}

.tutorial-line {
    border: 0.1rem solid var(--black);
}
.tutorial-step-wrapper {
    min-width: 300px;
    max-width: 400px;
    padding-right: 1rem;
}