
.interpret-tools-wrapper {
    @apply bg-slate-600;
    width: 100%;
    border-radius: var(--large-radius);
    padding: 0.5rem;
    /* display: flex;
    justify-content: space-between; */
}

.interpret-tools-btn {
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    min-width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1em;
    color: var(--black);
    border-radius: var(--large-radius);
    cursor: pointer;
    @apply bg-gray-300;
}