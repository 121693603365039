

.subscription-package {
    border: 3px solid var(--darkgray);
    padding: 1rem;
    border-radius: var(--large-radius);
    height: fit-content;
    width: 85%;
    max-width: 280px;
    position: relative;
}
.subscription-package.active {
    background: var(--white);
    border: 3px solid var(--gray);
}

.subscription-package:not(:first-child) {
    margin-top: 1rem;
}

.discount-input-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: -1rem;
    width: 85%;
    max-width: 280px;
}
.discount-input-wrapper button {
    width: fit-content;
    min-width: unset;
    padding: 0 0.5rem;
}

.center-modal.payment {
    height: 80%;
    width: 100%;
}
.trial-days-wrapper {
    width: fit-content;
    border-radius: 50%;
    position: absolute;
    right: 0.5rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: var(--white);
}

.discount-input {
    width: 85%;
    max-width: 280px;
}

.footer-spacing {
    margin-bottom: -1rem;
}
.subscription-package img {
    position: absolute;
    left: -1rem;
    top: -0.5rem;
}