/* .chat-user-list .entry:not(:first-child) {
    margin-top: 0.5rem;
} */

.chat-user-list {
    max-height: 120px;
    border-radius: var(--radius);
    padding: 0 0.5rem;
}
.badge.owner {
    @apply bg-yellow-400;
}
.badge.guest {
    @apply bg-emerald-700;
    color: var(--white-text);
}

@media (min-height: 860px) {
    .chat-user-list {
        max-height: 240px;
    }
}

@media (min-height: 1000px) {
    .chat-user-list {
        max-height: 360px;
    }
}

.nav-bar.user-list {
    background: var(--white);
    height: fit-content;
    border-radius: 0 var(--radius) var(--radius) 0;
    padding: 1rem 0.25rem 1rem 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    box-shadow: var(--subtle-outer-shadow);
    width: fit-content;
}

.entry {
    cursor: pointer;
    overflow-x: hidden;
}

.user-list-name {
    line-height: 1em;
    font-size: 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 0.5rem);
    margin-bottom: 0.1rem;
}