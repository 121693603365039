
.nav-bar {
    position: absolute;
    left: 0;
    top: 50%;
    width: 4rem;
    height: fit-content;
    padding: 0.5rem;
    background: transparent;
    color: var(--darkgray);
    transform: translateY(-50%);
    overflow: visible;
    transition: 0.3s;
    opacity: 1;
}
.nav-bar.hide {
    left: -5rem;
    opacity: 0;
}
/* .nav-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: fit-content;
    height: 100%;
    padding: 0.25rem;
    background: var(--white);
    color: var(--darkgray);
} */

.nav-bar.chatting {
    padding-left: 0;
    opacity: 1;
}
.nav-bar.chatting li {
    background: var(--white);
    border-radius: 0 2.5rem 2.5rem 0;
    padding-left: 1rem;
    transform: translateX(-0.5rem);
}

.special-nav-btn {
    /* @apply bg-gradient-to-r from-slate-700 to-pink-700 !important;  */
    background: linear-gradient(130deg, #334155, #be185d);
    -webkit-animation: color-wave 1s infinite;
    -moz-animation: color-wave 1s infinite;
    animation: color-wave 4s infinite;
    background-size: 200% 200%;
}
.nav-bar.chatting li.special-nav-btn {
    background: linear-gradient(130deg, #334155, #be185d);
    -webkit-animation: color-wave 1s infinite;
    -moz-animation: color-wave 1s infinite;
    animation: color-wave 4s infinite;
    background-size: 200% 200%;
}

.special-nav-btn img {
    filter: invert(100);
}

.logout-notification {
    position: fixed;
    top: -50%;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.center-modal-close-btn {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
}

.block-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 0%;
    opacity: 0;
    transition: 0.3s;
    background: var(--white);
}
.block-screen.active {
    height: 100%;
    width: 100%;
    opacity: 1;
}

.nav-bar-btn {
    transition: 0.3s;
    opacity: 1;
    transform-origin: 50% -50%;
}
.nav-bar-btn.chatting {
    width: 3.5rem;
}

.nav-bar-btn.chatting li {
    padding-left: 0.5rem;
    transform: translateX(0);
}
.nav-bar-btn .pin {
    /* box-shadow: -0.2rem 0 0.3rem -0.15rem rgba(0,0,0,0.3) inset; */
}
.nav-bar-btn .pin:hover:not(.no-hover), .nav-bar-btn .pin:active {
    background: var(--white) !important;
    color: var(--black) !important;
}
.nav-bar-btn .pin img, .nav-bar-btn .pin:hover img {
    filter: invert(0) !important;
}
.special-nav-btn .pin img, .special-nav-btn .pin:hover img {
    filter: invert(100) !important;
}
.nav-bar-btn.chatting img {
    filter: invert(100) !important;
}
.nav-bar-btn.hide {
    transform: scale(0.5);
    left: 0;
    opacity: 0;
}
.nav-bar-btn li {
    margin-left: -0.25rem;
}