
.search-user-results {
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @apply bg-neutral-200;
    border-radius: var(--radius);
    padding: 0;
    transition: 0.3s;
  }
  .search-user-results.show {
    height: 5rem;
    padding: 1rem;
  }

  .friend-request-sent-status {
    position: absolute;
    bottom: 0.35rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: var(--subtle-outer-shadow);
  }

  .friend-request-sent-status.badge {
    background: var(--black);
    color: var(--white-text);
    white-space: nowrap;
  }