
.tools-phrase-wrapper {
    border-radius: var(--radius);
    flex-grow: 1;
    box-shadow: 0 0 0.5rem rgba(0,0,0,0.8) inset;
    overflow: hidden;
    display: flex;
    transition: 0.3s;
}
.tools-phrase-wrapper img {
    aspect-ratio: 1 / 1;
}

.tools-entry {
    position: relative;
    font-size: 12px;
    font-weight: 300;
    color: var(--black);
    @apply bg-slate-300;
    border-radius: var(--radius) 0 0 var(--radius);
    padding: 0.5rem 0.5rem;
    transition: 0.2s;
    line-height: 1em;
    cursor: pointer;
    white-space: nowrap;
}
.focus .tools-entry {
    font-weight: 500;
}
.tools-entry.active {
    font-weight: 600;
}

.tool-box-col {
    max-height: calc(5.5rem + 120px);
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    box-shadow: -0.5rem 0 0.3rem rgba(0,0,0,0.3) inset;
    transition: 0.3s;
}
.tool-box-col.active .pin img {
    filter: none !important;
}

/* EXPANDED */
.tool-box-holder.phrase-expand {
    height: calc((8rem + 120px) * 2);
}
.phrase-expand .tool-box-col {
    max-height: calc(16rem + 240px - 2.5rem);
}
.phrase-expand .tools-entry {
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem 0.75rem;
}


/* SCENARIOS REST */
.col-scenario {
    flex-grow: 0;
    width: 20%;
    padding-left: 0.5rem;
}
.scenario-entry {
    width: 100%;
    white-space: nowrap;
    box-shadow: -0.5rem 0 0.3rem rgba(0,0,0,0.3) inset;
}
/* SCENARIOS ACTIVE */
.col-scenario.shrink {
    width: 10%;
    opacity: 80%;
}
.col-scenario.shrink .pin {
    margin-right: -1rem;
    transition-timing-function: ease-in;
}
.col-scenario.expand {
    flex-grow: 1;
}
.scenario-entry.active {
    background: var(--white);
    box-shadow: -0.6rem 0 0.3rem -0.3rem rgba(0,0,0,0.3) inset;
}


/* BUCKETS REST */
.col-buckets {
    flex-grow: 0;
    width: 20%;
    padding-left: 0.5rem;
    @apply bg-neutral-300;
}
.bucket-entry {
    width: 100%;
    border-radius: var(--radius) 0 0 var(--radius);
    white-space: nowrap;
    background: var(--gray);
    color: var(--white);
    box-shadow: -0.6rem 0 0.3rem rgba(0,0,0,0.3) inset;
}
/* BUCKETS ACTIVE */
.col-buckets.focus {
    flex-grow: 1;
}
.col-buckets.active {
    flex-grow: 0;
}
.col-buckets.expand {
    flex-grow: 1;
}
.col-buckets.shrink {
    width: 10%;
    opacity: 0.8;
}
.col-buckets.shrink .pin {
    margin-right: -1rem;
    transition-timing-function: ease-in;
}
.bucket-entry.active {
    color: var(--black);
    background: white;
    box-shadow: -0.5rem 0 0.3rem -0.3rem rgba(0,0,0,0.3) inset;
}

/* PHRASES REST */
.col-phrases {
    background: var(--white);
    padding-left: 0.5rem;
    flex-grow: 0;
}
.col-phrases.default {
    flex-grow: 1;
}
.phrase-entry {
    background: var(--gray);
    color: var(--white-text);
    border-radius: var(--radius) 0 0 var(--radius);
    box-shadow: -0.5rem 0 0.3rem rgba(0,0,0,0.3) inset;
    display: flex;
    justify-content: flex-start;
}
.phrase-entry .alt-text {
    height: 100%;
    align-self: center;
    font-size: 1rem;
}
.phrase-entry-limit {
    margin-left: -0.25rem;
    margin-right: 0.25rem;
    align-self: center;
    font-size: 0.6rem;
}
.phrase-entry-limit img {
    height: 0.7rem;
}
/* PHRASES ACTIVE */
.col-phrases.shrink {
    flex-grow: 0;
    width: 20%;
    opacity: 0.8;
}
.col-phrases.expand {
    flex-grow: 1;
}
.phrase-entry:active {
    font-weight: 600;
    background: var(--lightgray);
    color: var(--black);
    transform: scale(0.98);
    box-shadow: -0.5rem 0 0.3rem -0.3rem rgba(0,0,0,0.3) inset;
}
.phrase-entry.speaking {
    font-weight: 600;
    background: var(--lightgray);
    color: var(--black);
    box-shadow: -0.5rem 0 0.3rem -0.3rem rgba(0,0,0,0.3) inset;
    @apply animate-pulse;
}



/* backup */
/* .tool-box-col {
    max-height: calc(5.5rem + 120px);
    height: auto;
    transition: 0.2s;
}
.tool-box-col.focus {
    background: var(--white);
    padding: 0.5rem;
    border-radius: var(--radius);
    grid-column: span 2 / span 2;
}
.col-buckets.focus {
    grid-column: span 2 / span 2;
    margin-right: 0.5rem;
}

.col-buckets.focus .pin, .col-buckets.active .pin, .col-buckets.active .pin img {
    filter: none !important;
}
.col-buckets.active {
    padding-right: 0;
    margin-right: 0;
    grid-column: unset;
    border-radius: var(--radius) 0 0 var(--radius);
}
.col-phrases.default {
    grid-column: span 2 / span 2;
}
.col-phrases.active {
    padding: 0.5rem;
    border-radius: 0 var(--radius) var(--radius) 0;
    @apply bg-neutral-300;
}
.col-phrases.focus .pin {
    filter: none !important;
}

.tool-box-col.focus.first {
    border-radius: 0 var(--radius) var(--radius) var(--radius);
}
.tool-box-col.focus.last {
    border-radius: var(--radius) var(--radius) var(--radius) 0;
}

.tools-entry {
    position: relative;
    font-size: 12px;
    font-weight: 300;
    color: var(--black);
    @apply bg-slate-300;
    border-radius: var(--radius);
    padding: 0.5rem 0.5rem;
    margin-right: 0.5rem;
    transition: 0.2s;
    word-wrap: break-word;
    line-height: 1em;
    cursor: pointer;
}
.focus .tools-entry {
    font-weight: 500;
}
.focus .bucket-entry {
    margin-right: 0;
}
.active .bucket-entry:not(.bucket-entry.active) {
    margin-right: 0.5rem;
}
.col-phrases.active .tools-entry {
    background: var(--gray);
    font-weight: 400;
    color: var(--white);
}
.tool-box-col:last-child .tools-entry {
    margin-right: 0;
}

.tools-entry.active {
    font-weight: 600;
    margin-right: 0;
    border-radius: var(--radius) 0 0 var(--radius);
    background: var(--white);
}
.focus .tools-entry.active {
    font-weight: 600;
    @apply bg-neutral-300;
}
.phrase-entry:active {
    font-weight: 600;
    background: var(--white);
    transform: scale(0.98);
}
.phrase-entry.speaking {
    font-weight: 600;
    background: var(--white);
}
.phrase-entry {
    display: flex;
    justify-content: flex-start;
}
.phrase-entry .alt-text {
    height: 100%;
    align-self: center;
    font-size: 1rem;
}

.phrase-entry-limit {
    margin-left: -0.25rem;
    margin-right: 0.25rem;
    align-self: center;
    font-size: 0.6rem;
}
.phrase-entry-limit img {
    height: 0.7rem;
} */