.scale-wrapper {
    padding: 0.5rem;
    min-height: 112px;
    max-height: 33vh;
    min-width: 260px;
    border-radius: var(--radius);
    @apply bg-neutral-200;
}

.ticks {
    position: relative;
    display: flex;
    justify-content: flex-start;
}
.scale-tick {
    position: absolute;
    width: 1rem;
    height: 1rem;
    margin-top: 0.05rem;
    font-size: 0.6rem;
    font-weight: 400;
    transition: background 0.2s;
}
.scale-wrapper .scale-tick.active {
    border-radius: 50% 50% 0 0;
    width: 1rem;
    height: 1rem;
    font-weight: 600;
    margin-left: -0.35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
}

.scale-wrapper .tick-info {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: var(--radius);
    background: var(--white);
    font-size: 1rem;
    font-weight: 600;
    line-height:1.2em;
    display: flex;
    justify-content: center;
}
.scale-wrapper .tick-info p {
    font-size: 1rem;
    font-weight: 500;
    line-height:1.2em;
    align-self: center;
}
.scale-wrapper .tick-info h4 {
    font-weight: 800;
}