.animate-shake	{
    animation: shake 0.5s linear 1;
}
  
@keyframes shake {
    0% {
        transform: rotate(0deg) skew(0deg);
    }
    10%, 40%, 70% {
        transform: rotate(20deg) skew(10deg);
    }
    25%, 55%, 85% {
        transform: rotate(-20deg) skew(-10deg);
    }
    100% {
        transform: rotate(0deg) skew(0deg);
    }
}

.tree-container {
    position: relative;
    padding: 1rem;
    width: 100%;
    /* border-radius: var(--large-radius); */
    /* margin: auto 0; */
}

@media (min-width: 768px)  {
    .tree-container {
        min-width: 550px;
    }
}

.tree-form-wrapper {
    position: relative;
    background: var(--white);
    border-radius: var(--large-radius);
    padding: 1rem 1rem 0 1rem;
    max-width: 90%;
    margin: 0 auto;
    margin-top: -3rem;
    box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.4);
}

.cancel-tree-form {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
}
.submit-tree-form {
    margin-bottom: -1rem;
}
.submit-tree-form-error {
    margin-bottom: -1rem;
}


.tree-cat-btn {
    padding: 0 1.5rem;
    width: fit-content;
    min-width: fit-content;
    font-weight: 600;
    font-size: 1rem;
    color: var(--black);
    border-right: 1px solid var(--gray);
    border-left: 1px solid var(--gray);
    border-radius: 0;
    cursor: pointer;
    @apply bg-gray-300;
}
.tree-cat-btn:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
    border: none;
}
.tree-cat-btn:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    border: none;
}

.tree-cat-btn.active {
    background: var(--darkgray);
}