
.active-chat-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    color: var(--white-text);
    background: linear-gradient(130deg, #334155, #be185d);
    -webkit-animation: color-wave 1s infinite;
    -moz-animation: color-wave 1s infinite;
    animation: color-wave 4s infinite;
    position: fixed;
    right: 1rem;
    top: 1rem;
    box-shadow: var(--alt-outer-shadow);
    cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    width: 11rem;
    height: fit-content;
    overflow: hidden;
    transition: 0.8s;
  }

  .active-chat-notification.shrink {
    box-shadow: none;
    width: 3rem;
    height: 3rem;
    padding: 0;
    justify-content: start;
  }
  .active-chat-notification.shrink:hover {
    box-shadow: var(--alt-outer-shadow);
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    width: 11rem;
    height: 4.3rem;
    justify-content: center;
  }
  .active-chat-notification p {
    font-weight: 400;
  }
  .active-chat-notification h4 {
    font-weight: 700;
  }