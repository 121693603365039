.chatlobby-container {
    width: 66%;
    max-width: 240px;
}

.bg-container {
    transition: all ease-in 0.3s;
    transition-delay: 0.2s;
}
.bg-container.flip {
    transform: rotateY(90deg);
    transition-delay: 0s;
}

.animate-walk-left {
    animation: walk-left 7s infinite;
}	
  
@keyframes walk-left {
    0% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    48% {
        transform: translateX(-8rem);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    52% {
        transform: translateX(-8rem) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    96% {
        transform: translateX(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    100% {
        transform: translateX(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
}

.animate-walk-left-long {
    animation: walk-left-long 10s infinite;
}	
  
@keyframes walk-left-long {
    0% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    48% {
        transform: translateX(-12rem);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    52% {
        transform: translateX(-12rem) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    96% {
        transform: translateX(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    100% {
        transform: translateX(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
}

.animate-hop {
    animation: hop 4.5s 3;
}

@keyframes hop {
    0% {
        transform: translateY(0) rotateY(0deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    2% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    13% {
        transform: translateY(-45%) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    25% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    48% {
        transform: translateY(0) rotateY(180deg);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    52% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    65% {
        transform: translateY(-45%) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    78% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    100% {
        transform: translateY(0) rotateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
}


.alert-chat-active {
    position: fixed;
    top: -15rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    transition: 0.8s;
    width: fit-content;
    overflow: visible;
}
.alert-chat-active.show {
    top: 5rem;
}
.alert-chat-icon {
    position: absolute;
    top: 0rem;
    right: -0.5rem;
    @apply animate-bounce;
}
.alert-chat-icon img {
    height: 1.5rem;
    width: 1.5rem;
    -webkit-filter: drop-shadow(0 0.5rem 0.25rem rgba(0,0,0,0.3));
    filter: drop-shadow(0 0.5rem 0.25rem rgba(0,0,0,0.3));
}

.alert-chat-active span {
    background: var(--white);
    padding: 0.5rem 1.5rem;
    position: relative;
    border-radius: var(--large-radius);
    font-weight: 600;
    font-size: 0.8rem;
    width: fit-content;
    white-space: nowrap;
    box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(0,0,0,0.3);
    @apply animate-bounce;
}

.special-btn {
    font-size: 1.25rem;
    line-height: 1em;
    padding: 0.5rem 1.5rem;
    height: fit-content;
    /* width: 148px; */
    transition: 0.5s;
    margin-top: -0.75rem;
    background: linear-gradient(130deg, #334155, #be185d);
    -webkit-animation: color-wave 1s infinite;
    -moz-animation: color-wave 1s infinite;
    animation: color-wave 4s infinite;
    background-size: 200% 200%;
}
.special-btn:hover, .special-btn:active {
    /* background: linear-gradient(130deg, #334155, #be185d); */
    animation-play-state: paused;
}

.chatlobby-close-holder {
    position: absolute;
    top: -0.75rem;
    right: -1.25rem;
}

.session-display {
    margin-top: -0.5rem;
}

.invite-modal {
    margin: 0 auto 0 auto !important;
    width: 80%;
}