
.cheat-form {
    max-width: 80%;
    margin: 0 auto 1.5rem auto;
}

.welcome-button {
    padding: 0 5rem;
}

.hide-screen-content {
    background-color: rgba(0,0,0,0);
}
.hide-screen-content .welcome-modal {
    opacity: 0;
}