.settings-form {
    max-width: 80%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.settings-form .profile-pic {
    /* margin-right: -3rem;
    margin-top: -2.5rem; */
    /* transform: translateX(1rem); */
    border-radius: 50%;
    opacity: 0.6;
    @apply h-36 w-36;
}
.settings-form .img-holder {
    border-radius: 50%;
    @apply bg-neutral-200;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-self: center;
    @apply h-36 w-36;
}
.settings-form .profile-pic.has-file {
    opacity: 1;
}

.settings-form .change-photo-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 0;
    padding: 0 0.75rem;
    margin-top: -1rem;
}
.settings-form select, .settings-form input {
    width: 160px;
}

.delete-user-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.settings-form label span {
    white-space: nowrap;
}

.photo-change-btn {
    margin-top: -2rem;
}

.photo-change-btn .pin {
    /* box-shadow: var(--alt-outer-shadow); */
}

.subscription-btn {
    width: 100%;
    border-radius: var(--radius);
    background: var(--white);
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
}

.settings-form-wrapper {
    padding: 0 0.5rem 0.5rem 0.5rem;
    height: calc(100vh - 25rem - env(safe-area-inset-bottom));
    max-height: 25rem;
    border-radius: var(--radius);
}
.settings-form-wrapper label:not(:first-child) {
    margin-top: 1rem;
}

.contact-footer {
    background: var(--white);
    padding: 0.5rem 2rem;
    margin-bottom: -1rem;
    border-radius: 1rem 1rem 0 0;
    cursor: pointer;
}

.settings-tutorial-button {
    position: absolute;
    right: 0.25rem;
    bottom: 0.1rem;
}

@media only screen and (max-height: 739px) {
    .settings-form .profile-pic {
        /* margin-top: -5rem; */
    }
    .settings-form .img-holder {
        margin-top: -4rem;
    }
    .settings-form .img-holder.shift-down {
        margin-top: -3.5rem;
    }
    .settings-form-wrapper {
        height: calc(100vh - 25rem  - env(safe-area-inset-bottom, 10vh));
    }
    .settings-form-wrapper.iOS-shrink {
        height: calc(100vh - 30rem  - env(safe-area-inset-bottom, 10vh));
    }
}

.tipOptionsHeader {
    width: fit-content;
    margin-left: -1rem;
    display: flex;
    align-items: center;
    min-width: 240px;
}
.cheat-form {
    max-width: 80%;
    margin: 0 auto 1.5rem auto;
}
.hide-screen-content {
    background-color: rgba(0,0,0,0);
}
.hide-screen-content .main-modal {
    opacity: 0;
}