
.limit-category-btn {
    padding: 0.25rem 1.5rem;
    width: fit-content;
    min-width: 100px;
    font-weight: 600;
    font-size: 0.8rem;
    color: var(--black);
    border-radius: 0;
    cursor: pointer;
    @apply bg-gray-300;
}
.limit-category-btn:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
    border-right: 1px solid var(--gray);
}
.limit-category-btn:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    border: none;
}

.limit-category-btn.active {
    background: var(--darkgray);
    color: var(--white);
    font-weight: 600;
}
.limit-category-description {
    border-radius: var(--large-radius) var(--large-radius) 0 0;
    padding: 0.5rem;
    @apply bg-neutral-200;
}
.limit-category-output {
    border-radius: 0 0 var(--large-radius) var(--large-radius);
    padding: 0.5rem;
    min-height: calc(112px + 60px);
    max-height: 33vh;
    @apply bg-neutral-200;
}
.scale-category-output {
    border-radius: 0 0 var(--large-radius) var(--large-radius);
    padding: 0.5rem;
    min-height: 112px;
    max-height: 33vh;
    @apply bg-neutral-200;
}
.choice-entry {
    position: relative;
    display: flex;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: var(--radius);
    box-shadow: var(--subtle-outer-shadow);
    @apply bg-slate-300;
}
.choice-entry p {
    font-weight: 400;
}
.remove-choice-entry {
    position: absolute;
    right: 0;
    top: 0.25rem;
}
.ticks {
    position: relative;
    display: flex;
    justify-content: flex-start;
}
.scale-tick {
    position: absolute;
    width: 1rem;
    height: 1rem;
    margin-top: 0.05rem;
    font-size: 0.6rem;
    font-weight: 400;
    transition: background 0.2s;
}
.scale-tick.active {
    border-radius: 50% 50% 0 0;
    width: 1rem;
    height: 1rem;
    font-weight: 600;
    margin-left: -0.35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    padding-bottom: 0.1rem;
}

.tick-info {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: var(--radius);
    background: var(--white);
    font-size: 0.8rem;
    font-weight: 400;
    line-height:1.2em;
    display: flex;
    justify-content: space-between;
}
.tick-info p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height:1.2em;
    align-self: center;
}

.tick-info h5 {
    font-size: 1.5rem;
}