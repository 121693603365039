

.profile-pic {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0.6;
    @apply h-24 w-24;
}
.profile-pic.has-file {
    opacity: 1;
}

.large .profile-pic {
    @apply h-36 w-36;
}
.small .profile-pic {
    @apply h-12 w-12;
}
.mini .profile-pic {
    @apply h-8 w-8;
}
.micro .profile-pic {
    @apply h-6 w-6;
}

.profile-pic-holder {
    position: relative;
    min-width: fit-content;
    box-sizing: border-box;
    border-radius: 50%;
    width: fit-content;
}
.profile-pic-holder.micro {
    width: 1.5rem;
}
.profile-pic-holder .badge {
    margin-top: -1.25rem;
    font-size: 0.75rem;
}
.profile-pic-holder.mini .badge {
    position: absolute;
    bottom: -0.5rem;
    left: -50%;
    font-size: 0.6rem;
}
.profile-pic-holder.micro .badge {
    margin-top: -0.5em;
    font-size: 0.6rem;
}
.profile-pic-holder .badge.small {
    position: absolute;
    bottom: 0;
    font-size: 10px;
    left: 50%;
    transform: translateX(-50%);
}
.profile-pic-holder.owner {
    border: 3px solid rgb(234, 179, 8);
    margin-left: -3px;
}
.profile-pic-holder.background {
    background: var(--white);
}
.profile-pic-holder.background.bgNeutral {
    @apply bg-neutral-200;
}

.profile-status {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid var(--lightgray);
    @apply h-2 w-2 bg-red-600;
}
.profile-status.active {
    border-radius: 50%;
    @apply h-2 w-2 bg-green-600;
}
.profile-status.banned {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    @apply h-8 w-8;
    bottom: -0.25rem;
    right: -0.25rem;
}
.profile-status.banned img {
    height: 2rem;
    width: 2rem;
    filter: invert(20%) sepia(62%) saturate(4516%) hue-rotate(353deg) brightness(95%) contrast(82%) drop-shadow(0 0 0.01rem white);
}
.profile-status.banned .pin {
    height: 2rem;
    width: 2rem;
}
