.twoWay-mic-holder {
    position: relative;
    border-radius: 3rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}
.twoWay-mic-holder.left {
    padding: 0.25rem 0.25rem 0.25rem 3rem;
    margin-left: -2rem;
    @apply bg-emerald-700;
}
.twoWay-mic-holder.right {
    padding: 0.25rem 3rem 0.25rem 0.25rem;
    margin-right: -2rem;
    @apply bg-amber-400;
}
.twoWay-mic-holder .pin:first-child {
    position: absolute !important;
    left: 0;
    top: 0;
}
.twoWay-mic-holder.left .title {
    color: var(--white-text);
}
.twoWay-mic-holder.right .title {
    color: var(--black);
}

.twoWay-mic-holder .pin {
    box-shadow: var(--subtle-outer-shadow);
}
.twoWay-mic-holder .pin.mic-active {
    @apply bg-red-700;
}
.twoWay-mic-holder .pin.mic-active img {
    filter: invert(1);
}

.twoWay-select {
    color: var(--black);
}

.twoWay-audio {
    opacity: 0.5;
    transform: scale(1.2);
    transition: 0.3s;
    cursor: pointer;
}
.twoWay-audio.active {
    opacity: 1;
    transform: scale(1.5);
    filter: invert(1);
}

.auto-send {
    align-self: center;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: var(--large-radius);
    color: #ffffff99;
    @apply bg-gray-800;
    transition: 0.2s;
    cursor: pointer;
}
.auto-send.active {
    color: var(--white-text);
    @apply bg-pink-800;
    box-shadow: var(--inner-shadow);
}
.auto-send.twoWay {
    position: absolute;
    left: -3.25rem;
    padding-right: 2rem;
}

.speech-tool-status {
    white-space: nowrap;
}