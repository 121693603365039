

.instruct-holder {
    height: calc(100%);
    width: calc(100% + 1rem);
    margin-top: -0.5rem;
    border-radius: var(--large-radius);
    display: flex;
    justify-content: center;
    align-items: center;
}
.instruct-content {
    /* text-align: center;
    align-self: center; */
    border-radius: var(--radius);
    background: var(--white);
    color: var(--black);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2em;
    min-height: 3rem;
    max-height: 60%;
    height: fit-content;
    min-width: 90%;
    padding: 1rem;
    justify-content: center;
    display: flex;
}


    
    
    