
.userPicker {
    position: absolute;
    top: 0;
    left: -0.5rem;
    height: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem 0.5rem 1rem;
    transition: 0.1s;
}
.userPicker.show {
    height: 2.5rem;
    top: -2.5rem;
}

.userPicker .pick-entry {
    font-weight: 600;
    cursor: pointer;
}
.userPicker .pick-entry:hover {
    @apply text-blue-600;
}

.pick-list {
    height: 0;
    position: relative;
    right: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    @apply bg-neutral-200;
    border-radius: var(--large-radius) 0 0 var(--large-radius);
    box-shadow: 0 0.2rem 0.3rem rgba(0,0,0,0.4);
    transition: 0.3s;
}

.show .pick-list {
    height: 2rem;
}

.pick-list.hide {
    right: -100vw;
}

.targeted-entry {
    height: 0;
    display: flex;
    align-items: center;
    flex-grow: 0;
    padding: 0 0.5rem;
    font-weight: 600;
    font-size: 12px;
    background: var(--black);
    @apply text-blue-200;
    border-radius: var(--large-radius);
    box-shadow: 0 0.2rem 0.3rem rgba(0,0,0,0.6);
    cursor: pointer;
    transition: 0.1s;
}
.show .targeted-entry {
    height: 2rem;
}
.show .targeted-entry:hover:not(.header) {
    color: var(--white-text);
}

.show .targeted-entry.header {
    font-weight: 600;
    border-radius: 50%;
    min-width: 2rem;
    max-width: 2rem;
    height: 2rem;
    justify-content: center;
    padding: 0;
    cursor: default;
}