

.speaker-panel {
    overflow: hidden;
    background: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    transform: translateY(0.25rem);
}

.instruct-holder {
    position: relative;
}
.tools-category.speaker-tools {
    grid-template-columns: 1fr 1fr;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.tools-connect.speaker-tools {
    grid-template-columns: 1fr 1fr;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.tool-box-wrapper.speaker-tools {
    border-radius: var(--radius) var(--radius) 0 0;
}
.tool-box-holder.speaker-tools {
    border-radius: var(--radius) var(--radius) 0 0;
}
.instruct-lang-wrapper.speaker-tools {
    justify-content: center;
    color: black;
    margin: 0.5rem 0;
    flex-grow: 0;
}
.instruct-audio-wrapper.speaker-tools {
    flex-grow: 1;
    align-items: center;
}
.speaker-lang-select {
    width: 200px;
}
.instruct-holder.speaker-tool {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}
.speaker-tool .instruct-content {
    display: flex;
    max-height: 60%;
    height: fit-content;
    justify-content: center;
}
.panel-form.speaker-tool {
    padding: 0 0.5rem;
}
.speaker-tool .msg-status {
    right: 5rem;
}

.instruct-holder-expand-btn {
    position: absolute;
    right: 0;
    top: 0;
}
.instruct-content.expand-frame {
    max-height: 90%;
    height: 90%;
}
.instruct-content.expand-font {
    font-size: 3rem;
    line-height: 1em;
}
.instruct-content {
    transition: 0.3s;
}