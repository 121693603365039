.privacy h5 {
    margin: 1rem 0 1rem 0;
}

.privacy p {
    margin: 0.5rem 0;
}
.privacy .p-title {
    margin: 0.5rem 0;
}
.privacy .p-italic {
    font-style: italic;
}

.privacy ul {
    list-style: square;
    padding-left: 1rem;
}
.privacy li {
    font-size: 0.8rem;
    font-weight: 300;
}