
.tools-category {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    border-radius: var(--radius);
    transition: 0.2s;
    padding: 0 0.5rem;
}
.tools-category.limited {
    grid-template-columns: 1fr 1fr;
}

.tools-connect {
    height: 0;
    box-sizing: border-box;
    padding: 0 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    transition: 0.2s;
}
.show .tools-connect {
    height: 0.5rem;
}
.tools-connect.limited {
    grid-template-columns: 1fr 1fr;
}
.tools-connect-unit {
    box-sizing: border-box;
    height: 0;
    background: var(--white);
    transition: 0.2s;
}
.tools-connect-unit.active {
    height: 0.5rem;
    @apply bg-slate-600;
}

.tools-category-option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0.5rem; */
    font-size: 2rem;
    font-weight: 600;
    color: var(--black);
    @apply bg-neutral-300;
    line-height: 1em;
    border-radius: var(--radius);
    height: 0px;
    overflow: hidden;
    transition: 0.2s;
    cursor: pointer;
}
.show .tools-category-option {
    padding: 0.5rem;
    height: 60px;
}
.tools-category-option img {
    position: absolute;
}
.show .tools-category-option img {
    position: relative;
}

.tools-category-option.deactive {
    @apply bg-slate-400;
}

.tools-category-option:active {
    @apply bg-slate-600;
    color: var(--white);
}
.tools-category-option.active {
    border-radius: var(--radius) var(--radius) 0 0;
    @apply bg-slate-600;
    color: var(--white);
}
.tools-category-option img {
    height: 2rem;
}
.shrink .tools-category-option {
    height: 2rem;
    font-size: 1rem;
}
.shrink .tools-category-option img {
    height: 1rem;
}

/* .tool-box-wrapper {
    position: relative;
    transition: 0.2s;
    height: 0;
    bottom: -100%;
    overflow: hidden;
    color: var(--white);
    padding: 0.5rem;
    @apply bg-slate-600;
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
}
.tool-box-wrapper.show {
    padding: 0.5rem;
    bottom: 0;
    height: calc(8rem + 120px);
} */
.tool-box-wrapper {
    position: relative;
    height: 0;
    transition: 0.2s;
    overflow: hidden;
    @apply bg-slate-600;
    border-radius: var(--radius);
}
.tool-box-wrapper.show {
    height: calc(8rem + 120px);
}
.tool-box-wrapper.phrase-expand {
    overflow: visible;
}

.tool-box-holder {
    position: absolute;
    transition: 0.2s;
    bottom: -100vh;
    left: 0;
    width: 100%;
    height: calc(8rem + 120px);
    overflow: hidden;
    color: var(--white);
    padding: 0.5rem;
    @apply bg-slate-600;
    border-radius: var(--radius);
    display: flex;
    flex-direction: column;
    transition: 0.3s;
}
.show .tool-box-holder {
    bottom: 0;
}

.tools-limit-wrapper {
    box-sizing: border-box;
    height: calc(100% - 1.5rem);
    border-radius: var(--radius);
    flex-grow: 1;
}
.tools-limit-entry {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1em;;
    height: 60px;
    min-width: 60px;
    @apply bg-slate-300;
    color: var(--black);
    border-radius: var(--radius);
    transition: 0.2s;
    cursor: pointer;
}
.tools-limit-entry.active, .tools-limit-entry:active {
    @apply bg-slate-50;
    color: var(--black);
    font-weight: 800;
    /* transform: scale(1.02); */
}
.tools-limit-entry .limit-category {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    color: var(--white);
    @apply bg-slate-600;
    border-radius: 0 0 50% 0;
    padding: 0.2rem 0.35rem 0.25rem 0.25rem;
    box-shadow: -0.25rem -0.25rem 0.25rem -0.1rem rgba(0,0,0,0.4) inset;
    transition: 0.2s;
}
.tools-limit-entry.active .limit-category, .tools-limit-entry:active .limit-category {
    box-shadow: unset;
}

.chat-mode-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--large-radius);
    font-size: 1rem;
    font-weight: 800;
    flex-grow: 1;
    @apply bg-neutral-300;
    color: var(--black);
    transition: 0.2s;
    cursor: pointer;
    width: 60%;
    height: 60%;
    max-height: 60%;
}
.chat-mode-btn:active, .chat-mode-btn.active {
    background: var(--white);
    border-radius: var(--radius);
    font-size: 0.8rem;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.chat-mode-btn.active p {
    font-size: 0.8rem;
}
.chat-mode-btn.deactive {
    font-weight: 600;
    font-size: 0.8rem;
    @apply bg-slate-400;
}
.chat-mode-btn.deactive:active, .chat-mode-btn.deactive.active {
    @apply bg-slate-400;
}
