.help-bubble {
    position: absolute;
    bottom: 2.5rem;
    font-size: 10px;
    background: var(--white);
    padding: 1rem;
    border-radius: var(--radius);
    width: 80%;
    max-width: 500px;
    max-height: 40%;
    transition: 0.8s;
    cursor: pointer;
}
.help-bubble.hide {
    bottom: -50%;
}
.help-bubble.shadow {
    box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(0,0,0,0.2);
}

.help-bubble-btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}
.help-bubble-btn.active img {
    filter: invert(1);
}
.help-bubble-btn.active .pin:hover, .help-bubble-btn.active .pin:active {
    filter: none;
}
