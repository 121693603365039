
.friend-entry {
    position: relative;
    display: flex;
    width: calc(100% - 1rem);
    height: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 5rem;
}


.friend-request-entry {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 2rem);
    height: fit-content;
    background: var(--white);
    padding: 0.25rem 0.5rem;
    border-radius: 5rem;
    overflow-x: hidden;
    transition: 0.5s;
  }
  .friend-request-entry.hidden {
    box-shadow: -0.5rem 0 0.25rem rgba(0,0,0,0.2) inset;
  }
  
  .friend-request-accept .pin:hover {
    @apply bg-green-600 !important;
  }
  .friend-request-accept .pin:hover img {
    filter: invert();
  }
  .friend-request-deny .pin:hover {
    @apply bg-red-600 !important;
  }
  .friend-request-deny .pin:hover img {
    filter: invert();
  }
  
  .friend-request-status-block {
    position: absolute;
    height: 100%;
    width: 0;
    border-radius: 5rem;
    @apply bg-slate-700;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
  }
  .friend-request-status-block.show {
    width: 9rem;
  }

  .icon-img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .invite-error {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.3s;
  }

  .invite-error.show {
    bottom: -1rem;
    opacity: 1;
    height: fit-content;
  }

  .invited-friend {
    font-weight: 600;
    font-size: 10px;
    border-radius: 5px;
    @apply bg-cyan-700;
    color: var(--white-text);
    padding: 0.25rem 0.5rem;
    margin-right: -0.25rem;

  }