

.home-bg {
    /* background: linear-gradient(165deg, #ccfbf1aa, var(--white), var(--white), var(--white)); */
    /* background: linear-gradient(140deg, #ccfbf155, #ffedd555); */
    /* background: linear-gradient(140deg, #ffedd5aa, var(--white)); */
    /* background: linear-gradient(140deg, #34d399, #d97706); */
    /* backdrop-filter: blur(5px); */
    /* background: linear-gradient(165deg, var(--white), var(--white), var(--white), #ccfbf1aa); */

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.home-backdrop {
    /* position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: url('../../assets/orange-2.png');
    background-repeat: space space;
    transform: rotate(-15deg) skew(15deg) translate(0, -50%) scale(0.7);
    opacity: 0.5; */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(165deg, transparent, transparent, transparent, #ccfbf155);
    z-index: 1;
} 
.home-content-wrapper {
    width: 100%;
    /* margin-top: 100vh; */
    background: linear-gradient(165deg, #ccfbf155, #ffedd533, #ffffff99, var(--white), var(--white));
    box-shadow: 0 -1rem 10rem #ccfbf155;
    z-index: 2;

    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;
    transform-style: preserve-3d;
    position: relative;
    perspective: 10px;
}
.home-content-wrapper .content-holder {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;
    transform-style: preserve-3d;
    position: relative;
    perspective: 10px;
}
.home-content-wrapper .section-form {
    position: absolute;
    z-index: 1;
}
.home-content-wrapper .section-bg {
    transform: translateZ(-10px) scale(2);
    position: absolute;
    top: 225%;
    z-index: -1;
    width: 100%;
    padding-left: 2rem;
    opacity: 0.3;
    background: linear-gradient(165deg, transparent, var(--white), #ffedd533, #ccfbf155);
    box-shadow: 0 1rem 10rem #ccfbf155;
}
.home-content-wrapper .section-bg h3 {
    font-weight: 900;
    letter-spacing: -0.05em;
}
.home-content-wrapper .section-text {
    transform: translateZ(0) scale(1);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    width: 100%;
    background: linear-gradient(165deg, transparent, var(--white));
    padding-bottom: 33%;
}
/* .home-content-wrapper .section-text p {
    font-size: 0.8rem;
    margin: 100% 0 ;
    text-shadow: 0 0 2rem white;
} */
.home-content-wrapper h3 {
    margin: 2rem 0;
    margin-left: -2rem;
    font-weight: 700;
    opacity: 0.08;
    position: relative;
    font-size: 4.5rem;
    letter-spacing: -0.05em;
    white-space: nowrap;
    z-index: -1;
}
.home-content-wrapper p {
    z-index: 90;
}

.home-bg .center-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--large-radius);
    width: 100%;
    max-width: 450px;
    /* min-height: 600px;
    max-height: 800px;
    position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
}
.home-bg .bottom-button {
    /* position: absolute;
    bottom: 1rem; */
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.home-bg .bottom-button .btn-border {
    position: relative;
    background: #fff;
    transition: 0.2s;
}
.home-bg .bottom-button .btn-border:hover {
    color: var(--black);
    font-weight: bold;
}
.home-bg .bottom-button .btn-border::after {
    position: absolute;
    right: -0.5rem;
    top: -1rem;
    content: ' ✨';
    opacity: 0;
    transition: 0.3s;
    font-size: 1.5rem;
}
.home-bg .bottom-button .btn-border:hover::after {
    content: ' ✨';
    opacity: 1;
}
/* .home-bg form {
    border: 3px solid var(--black);
    border-radius: var(--large-radius);
    padding: 1.5rem 1rem;
} */
.home-segment {
    display: flex;
    height: 120px;
}

.text-section {
    position: relative;
    margin: 50% 1rem;
}
.text-section:first-child {
    margin: 0% 1rem 50% 1rem;
}

.text-section p {
    margin: 0;
    font-size: 0.8rem;
    text-shadow: 0 0 2rem white;
}

.text-section .img-holder {
    position: relative;
}
.section01 p {
    padding-bottom: 1rem;
}
.section01 .img01 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -4rem;
    right: -1rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img02 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -3.5rem;
    right: 2.5rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img03 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -3rem;
    right: 6rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img04 {
    height: 5rem;
    width: 5rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img05 {
    position: absolute;
    top: -7rem;
    right: 8rem;
    height: 4rem;
    width: 4rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img06 {
    position: absolute;
    top: -6.5rem;
    right: 8.25rem;
    margin: 0 auto;
}
.section01 .img07 {
    position: absolute;
    top: -7.75rem;
    right: 5rem;
    height: 4rem;
    width: 4rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img08 {
    position: absolute;
    top: -7.25rem;
    right: 5.25rem;
    margin: 0 auto;
}
.section01 .img09 {
    position: absolute;
    top: -8.25rem;
    right: 1.5rem;
    height: 4rem;
    width: 4rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section01 .img10 {
    position: absolute;
    top: -7.75rem;
    right: 1.75rem;
    margin: 0 auto;
}
.speech-bubble {
    display: none;
}
.speech-bubble.show {
    display: block;
}
.speech-bubble-content {
    height: 2.25rem;
    width: 3.5rem;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
}
.speech-bubble-content p {
    padding: unset;
    text-align: center;
    line-height: 1em;
}

.section02 p {
    padding-top: 1rem;
}
.section02 .img01 {
    height: 8rem;
    width: 8rem;
    margin: 0 auto;
}
.section02 .img02 {
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 0rem;
    right: 15%;
    transform: translateX(25%);
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section02 .img03 {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -2.5rem;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
}

.section03 p {
    padding-bottom: 1rem;
}
.section03 .img01 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -3rem;
    right: -2rem;
    transform: rotate(45deg);
}
.section03 .img02 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -3rem;
    right: 2.5rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    transform: rotate(90deg);
    z-index: 2;
}
.section03 .img03 {
    height: 5rem;
    width: 5rem;
    position: absolute;
    top: -3rem;
    right: 6.5rem;
}
.section03 .img04 {
    position: relative;
    height: 8rem;
    width: 8rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    z-index: 3;
}

.section04 p {
    margin: auto 0;
    padding-top: 2rem;
}
.section04 .img01 {
    position: absolute;
    height: 4.5rem;
    width: 4.5rem;
    top: 0;
    left: -0.5rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section04 .img02 {
    position: relative;
    height: 4.5rem;
    width: 4.5rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section04 .img03 {
    position: absolute;
    height: 4.5rem;
    width: 4.5rem;
    top: 0;
    right: -0.5rem;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
}
.section04 .img04 {
    position: absolute;
    height: 5rem;
    width: 5rem;
    top: 2rem;
    right: 50%;
    transform: translateX(50%);
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.5));
    filter: drop-shadow(0 0.2rem 0.3rem rgba(0,0,0,0.5));
}

.section05 p {
    margin: auto 0;
}
.section05 .img-holder {
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.4));
    filter: drop-shadow(0 0.5rem 0.3rem rgba(0,0,0,0.4));
    z-index: 3;
}

.section06 .img01 {
    position: absolute;
    height: 6rem;
    width: 6rem;
    top: 0.5rem;
    right: 50%;
    transform: translateX(50%);
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.5));
    filter: drop-shadow(0 0.2rem 0.3rem rgba(0,0,0,0.5));
}
.section06 .img02 {
    position: absolute;
    height: 5rem;
    width: 5rem;
    top: -2rem;
    right: 45%;
    transform: translateX(50%) rotate(-45deg);
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.5));
    filter: drop-shadow(0 0.2rem 0.3rem rgba(0,0,0,0.5));
}
.section06 .img03 {
    height: 4rem;
    width: 4rem;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0.3rem 0.3rem rgba(0,0,0,0.5));
    filter: drop-shadow(0 0.2rem 0.3rem rgba(0,0,0,0.5));
}

.flip-img {
    transform: scaleX(-1);
}

.footer {
    background: var(--black);
    color: var(--white-text);
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.gdpr-block {
    background: var(--black);
    color: var(--white-text);
    border-radius: var(--large-radius);
    padding: 1rem;
}

.home-banner-holder {
    position: relative;
    overflow: hidden;
}
.home-banner-01 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: 0.5s;
}
.home-banner-01.top {
    top: -1rem;
    opacity: 0;
}
.home-banner-01.bottom {
    top: 1rem;
    opacity: 0;
}
.home-banner-02 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: 0.5s;
}
.home-banner-02.top {
    top: -1rem;
    opacity: 0;
}
.home-banner-02.bottom {
    top: 1rem;
    opacity: 0;
}