
.chatInvite-holder {
    position: absolute;
    right: 0.5rem;
    bottom: 1.75rem;
    transition: 0.1s;
    background: linear-gradient(130deg, #334155, #be185d);
    animation: color-wave 4s infinite;
    border-radius: 50%;
    aspect-ratio: 1;
    cursor: pointer;
    transition: 0.3s;
    height: 2.5rem;
    width: 2.5rem;
    color: var(--white-text);
}

.chatInvite-holder .pin img {
    filter: invert();
}

.chatInvite-holder.show {
    box-shadow: var(--subtle-outer-shadow);
    height: 500px;
    width: 300px;
    max-height: 60vh;
    max-width: 60vw;
    bottom: 0.5rem;
    border-radius: 0.5rem;
    padding: 1rem;
}
.chatInvite-holder.show.small {
    height: 7rem;
    width: 300px;
}
.chatInvite-holder.hidden {
    display: none;
}

.chatInvite-holder .profile-pic.object-cover:not(.has-file) {
    filter: invert();
}

.invite-list-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.invite-entry {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    transition: 0.2s;
    transform: scale(1);
}
.invite-entry:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1.5rem;
}

.unread-entry {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1.5rem;
}

.invite-screen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    padding: 1rem;
}