.animate-shake	{
    animation: shake 0.5s linear 1;
}
  
@keyframes shake {
    0% {
        transform: rotate(0deg) skew(0deg);
    }
    10%, 40%, 70% {
        transform: rotate(20deg) skew(10deg);
    }
    25%, 55%, 85% {
        transform: rotate(-20deg) skew(-10deg);
    }
    100% {
        transform: rotate(0deg) skew(0deg);
    }
}

.phrase-list-wrapper {
    position: relative;
    border-radius: var(--large-radius);
    height: calc(100vh - 16.5rem);
    max-height: calc(100svh - 16.5rem);
    margin: 0 auto;
    min-width: 85%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.phrase-list-wrapper-large {
    column-count: 2;
    column-gap: 1rem;
}

.phrase-list-entry {
    position: relative;
    top: 0;
    border-radius: var(--radius);
    box-sizing: border-box;
    box-shadow: var(--subtle-outer-shadow);
    margin: 0 1rem;
    max-width: 450px;
    min-width: 205px;
    cursor: pointer;
    transition: 0.2s;
    z-index: 0;
}
.phrase-list-entry.highlight {
    transform: scale(1.05);
    box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.4);
    z-index: 1;
}

.phrase-list-entry .entry-header {
    background: var(--white);
    border-radius: var(--radius) var(--radius) 0 0;
    padding: 0.25rem 0.5rem;
}
.phrase-list-entry .entry-content {
    font-size: 0.6rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0 0 var(--radius) var(--radius);
    flex-grow: 1;
    @apply bg-slate-300;
}
.phrase-list-entry .alt-phrase {
    font-style: italic;
    font-size: 0.6rem;
}

.phrase-list-entry .up-arrow {
    position: absolute;
    display: none;
    top: -3.5rem;
    left: 50%;
    transform: translateX(-50%);
}
.phrase-list-entry .down-arrow {
    position: absolute;
    display: none;
    bottom: -3.5rem;
    left: 50%;
    transform: translateX(-50%);
}
.phrase-list-entry .down-arrow .pin, .phrase-list-entry .up-arrow .pin {
    /* filter: invert(0.8); */
    box-shadow: 0 0.5rem 0.3rem -0.1rem rgba(0,0,0,0.4);
}
.phrase-list-entry.highlight .down-arrow, .phrase-list-entry.highlight .up-arrow {
    display: block;
}
.phrase-list-entry.highlight:first-child .up-arrow {
    display: none;
}
.phrase-list-entry.highlight:last-child .down-arrow {
    display: none;
}