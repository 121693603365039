
.pin {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: var(--white);
    color: var(--black);
    transition: 0.1s;
    cursor: pointer;
}
.pin.x-large {
    height: 4rem;
    width: 4rem;
}
.pin.large {
    height: 3rem;
    width: 3rem;
}
.pin.small {
    height: 2rem;
    width: 2rem;
}
.pin.mini {
    height: 1.5rem;
    width: 1.5rem;
}
.pin.micro {
    height: 0.8rem;
    width: 0.8rem;
}
.pin.no-background {
    background: transparent;
}
.pin.neutral-background {
    @apply bg-neutral-200;
}
.pin.alt-color {
    background: var(--darkgray);
}
.pin.shadow {
    box-shadow: var(--alt-outer-shadow);
}


.pin img {
    height: 1.5rem;
    width: 1.5rem;
    /* filter: invert(7%) sepia(10%) saturate(3839%) hue-rotate(184deg) brightness(101%) contrast(96%); */
}
.pin.x-large img {
    height: 3rem;
    width: 3rem;
}
.pin.large img {
    height: 2rem;
    width: 2rem;
}
.pin.mini img {
    height: 1rem;
    width: 1rem;
}
.pin.micro img {
    height: 0.5rem;
    width: 0.5rem;
}

.active .pin:not(.no-background):not(.neutral-background) {
    background: var(--black);
    color: var(--white);
}
.pin:hover:not(.no-hover):not(.no-background):not(.neutral-background) {
    background: var(--black);
    color: var(--white);
}
.active .pin img, .pin:hover:not(.no-hover):not(.no-background):not(.neutral-background) img {
    filter: invert(100%);
}
.pin.alt-color img {
    filter: invert(100%);
}
.pin .tooltip {
    display: none;
    position: absolute;
    top: 0rem;
    left: calc(1rem + 100%);
    width: fit-content;
    background: var(--black);
    color: var(--white);
    font-weight: 500;
    padding: 0 1rem;
    border-radius: var(--radius);
    box-shadow: var(--outer-shadow);
    transition: 0.1s;
    white-space: nowrap;
}
.pin .tooltip.top {
    top: -2rem;
    left: -50%;
}
.pin.small .tooltip {
    font-size: 12px; 
    padding: 0 0.5rem;
}
.pin.no-background .tooltip {
    background: var(--white);
    color: var(--black);
}
.active .pin .tooltip {
    display: block;
}
/* .pin:hover .tooltip {
    display: block;
} */
.pin:active {
    transform: scale(0.95);
}

.pin.background {
    background: var(--black);
}