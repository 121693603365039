.animate-squeeze	{
    animation: squeeze 0.5s linear 1;
    transform-origin: 0 100%;
}
  
@keyframes squeeze {
    0% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    60% {
        transform: scale(1, 0.7);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    100% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.limit-container {
    position: relative;
    padding: 1rem;
    width: 100%;
}

.limit-form {
    padding-bottom: 0;
}

.limit-form .btn {
    margin-bottom: -1rem;
}

.cancel-form-btn {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
}
