

.twoWay-holder {
    height: calc(100%);
    width: calc(100%);
    border-radius: var(--large-radius);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.twoWay-other-holder {
    position: relative;
    top: -100%;
    background: var(--darkgray);
    width: 100%;
    padding: 1rem;
    border-radius: var(--large-radius);
    transition: 0.5s;
    /* box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.4); */
}
.twoWay-other-holder.show {
    top: 0;
}

.twoWay-other-holder .mic-holder {
    position: relative;
    width: 3rem;
    height: 3rem;
}
.twoWay-other-holder .mic-holder .pin {
    position: absolute;
    left: 0;
    top: 0;
}

.twoWay-other-content {
    display: block;
    width: 100%;
    border-radius: var(--radius);
    background: var(--white);
    color: var(--black);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2em;
    max-width: 600px;
    min-height: 3rem;
    height: 4rem;
    max-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    transition: 0.3s;
    box-shadow: -0.2rem -0.2rem 0.5rem rgba(0,0,0,0.6) inset, 0.3rem 0.3rem 0.5rem white inset;
}

/* .twoWay-other-content {
    border-radius: var(--radius);
    background: var(--white);
    color: var(--black);
    max-width: 600px;
    height: 3.5rem;
    padding: 1rem;
    text-align: center;
}
.twoWay-other-content p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2em;
}
.twoWay-other-content p:first-child {
    font-size: 1.5rem;
    line-height: 1.2em;
    margin-bottom: 0.25rem;
} */
.content-text {
    font-weight: 600;
}

.other-status {
    position: absolute;
    top: 0;
    right: 1rem;
    display: block;
    color: var(--white-text);
    transform: rotate(180deg);
    height: 0.6rem;
}

.twoWay-content {
    min-width: 90%;
    min-height: 3rem;
    height: 4rem;
    width: calc(100% - 2rem);
    align-self: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2em;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius);
    background: var(--white);
    color: var(--black);
    transition: 0.3s;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0,0,0,0.4) inset, -0.3rem -0.3rem 0.5rem white inset;
}
.twoWay-holder.isIOS {
    margin-top: 1rem;
}

.twoWay-other-holder .pin.mic-active {
    @apply bg-red-700;
}
.twoWay-other-holder .pin.mic-active img {
    filter: invert(1);
}


@media only screen and (max-height: 630px) {
    .twoWay-other-content {
        height: 3rem;
        max-height: 3rem;
    }
    .twoWay-content {
        height: 3rem;
        max-height: 3rem;
    }
}