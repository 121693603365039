

.chat-tools-btn {
    padding: 0 1rem;
    width: fit-content;
    min-width: 5rem;
    font-weight: 600;
    font-size: 0.9rem;
    white-space: nowrap;
    color: var(--white);
    border-right: 1px solid var(--gray);
    border-radius: 0;
    cursor: pointer;
    
    @apply bg-gray-300;
}
.chat-tools-btn:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
}
.chat-tools-btn:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    border: none;
}

.chat-tools-btn.active {
    background: var(--darkgray);
}
.chat-tools-btn:first-child:last-child {
    border-radius: var(--radius);
    border: none;
    min-width: 3rem;
}

@media (min-width: 768px) {
    .chat-tools-btn:first-child:last-child {
        width: 6rem;
    }
}