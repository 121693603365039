

.join-chat-btn {
    min-width: auto;
    padding: 0 0.5rem;
    border-radius: 0 var(--radius) var(--radius) 0;
}

.chatroom-list-holder {
    max-height: calc(100vh - 9.25rem - 1rem);
    min-width: 80%;
    border-radius: var(--radius);
    border-top: 1px solid var(--gray);
    border-bottom: 1px solid var(--gray);
    padding: 0.5rem;
}

.disclaimer {
    max-width: 200px;
    text-align: start;
}