.chat-panel {
    /* height: calc((100vh - 20px) - 7.5rem);
    max-height: calc((100svh - 20px) - 4.5rem); */
    /* height: calc(100vh - 7.25rem - env(safe-area-inset-bottom));
    max-height: calc(100svh - 5.25rem - env(safe-area-inset-bottom)); */
    height: calc(100vh - 7.25rem - env(safe-area-inset-bottom));
    height: calc((var(--ch, 1ch) * 100) - 7.25rem - env(safe-area-inset-bottom));
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-grow: 1;
    border-radius: var(--large-radius);
    background: var(--white);
    box-sizing: border-box;
}
.chat-panel.instruction-mode {
    /* height: calc((100vh - 20px) - 6rem); */
    /* height: calc(100vh - 5.75rem - env(safe-area-inset-bottom)); */
    height: calc(100vh - 5.25rem - env(safe-area-inset-bottom));
    height: calc((var(--ch, 1ch) * 100) - 5.25rem - env(safe-area-inset-bottom));
    @apply bg-neutral-200;
}
.chat-panel.twoWay-mode {
    /* height: calc((100vh - 20px) - 4.5rem); */
    /* height: calc(100vh - 5.25rem - env(safe-area-inset-bottom)); */
    height: calc(100vh - 5.25rem - env(safe-area-inset-bottom));
    height: calc((var(--ch, 1ch) * 100) - 5.25rem - env(safe-area-inset-bottom));
    @apply bg-neutral-200;
}
/* .chat-panel.instruction-mode.isIOS {
    margin-top: 1.5rem;
}
.chat-panel.twoWay-mode.isIOS {
    margin-top: 3rem;
    overflow: visible;

} */
.chat-panel .output {
    /* height: calc(100% - 5.5rem - env(safe-area-inset-bottom)); */
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0 0.5rem;
    transition: 0.2s;
    flex-shrink: 2;
}
.twoWay-mode .output, .instruction-mode .output {
    flex-shrink: 1;
    flex-grow: 1;
}

.send-msg-btn {
    min-width: auto;
    padding: 0 0.5rem;
    border-radius: 0 var(--radius) var(--radius) 0;
}
.send-msg-input {
    border-radius: var(--radius) 0 0 var(--radius);
}

.chat-panel .output .chat-entry-wrapper:first-child {
    margin-top: auto;
}

.chat-panel .output .chat-entry-wrapper:not(:first-child) {
    margin-top: 1rem;
}

.placeholder {
    background: blueviolet;
    width: 3rem;
    height: 1000px;
}

.chat-tools-wrapper {
    position: relative;
    transition: 0.3s;
    flex-shrink: 0;
}

.output.instruct {
    justify-content: center;
    align-items: center;
}
.output.twoWay {
    justify-content: center;
    align-items: center;
    padding: 0;
}

.instruction-banner {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    @apply bg-emerald-700;
    padding: 1.1225rem 0; /* 3.25rem, -0.8rem = 2.45rem, 2.45rem / 2 = 1.225rem */
}
.instruction-banner p {
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: center;
    color: var(--white-text);
}
.instruction-banner-close {
    position: absolute;
    right: 0;
}

.panel-form {
    position: relative;
    padding: 0 0.5rem;
    transform: none;
    transition: 0.3s;
}
.panel-form.twoWay-mode.isIOS {
    margin-top: 1rem;
}
.panel-form.hide {
    padding: 0 0.5rem;
    transform: translatex(150%);
}

.msg-status {
    position: absolute;
    right: 4rem;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
    text-align: end;
    cursor: pointer;
}
.msg-status-tip {
    position: absolute;
    display: none;
}
.tip .msg-status-tip {
    display: block;
    top: -1.5rem;
    left: -3rem;
    background: var(--darkgray);
    color: var(--white);
    border-radius: var(--radius);
    padding: 0 1rem;
    box-shadow: var(--outer-shadow);
    line-height: 1.2em;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
}
.tip .msg-status-tip.shift-left {
    left: -4rem;
}

.msg-loading {
    display: flex;
    justify-content: center;
    height: 0;
    opacity: 0;
    position: relative;
    transition: 0.3s;
    margin-top: auto;
}
.msg-loading.show {
    height: 1rem;
    opacity: 1;
}

.msg-loading .loading-icon { 
    position: relative;
    top: -2rem;
    transition: 0.3s;
}
.msg-loading.show .loading-icon { 
    top: 0;
}

.photo-file-error {
    position: absolute;
    top: -2rem;
    left: -0.25rem;
    white-space: nowrap;
    padding: 0.25rem 0.5rem !important;
    box-shadow: var(--outer-shadow);

}