
.btn-wait-text {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nobreak;
    color: var(--black);
    font-weight: 600;
    width: 100%;
}