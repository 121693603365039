
.invite-friendsList-wrapper {
    margin-top: 0.5rem;
    max-height: 50vh;
    max-height: 50svh;
    border-radius: 0.5rem;
}

.invite-modal .friend-entry {
    width: 100%;
}

.invite-modal {
    max-width: 450px;
}