
.chatroom-list-wrapper {
    border-radius: var(--radius);
    max-height: 100%;
    width: 100%;
    padding: 0.5rem;
}

.chatroom-entry {
    border-radius: var(--radius);
    box-shadow: var(--subtle-outer-shadow);
    cursor: pointer;
}

.chatroom-entry .header {
    background: var(--white);
    border-radius: var(--radius) var(--radius) 0 0;
    width: 100%;
    padding: 0.25rem 0.5rem;
}
.chatroom-entry .header p {
    font-size: 0.6rem;
}

.chatroom-entry .users {
    border-radius: 0 0 var(--radius) var(--radius);
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    @apply bg-neutral-300;
}