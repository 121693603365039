
.App {
  height: 100%;
  position: fixed;
  top: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  background: var(--white);
  opacity: 1;
  transition: 0.5s;
  max-width: 100vw;
  left: 0;
}
.App.block-content {
  opacity: 0;
}

.bg-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 4.25rem);
  width: calc(100% - 3rem);
  margin: 3.25rem 1.25rem 1.25rem 1.75rem;
  @apply bg-neutral-200;
  z-index: -1;
}

.center-element {
  margin: auto;
  max-width: 650px;
  width: fit-content;
  height: fit-content;
  border-radius: var(--radius);
  padding: 1.5rem;
}
.center-modal {
  position: relative;
  margin: auto;
  background: var(--white);
  padding: 1.5rem;
  border-radius: var(--radius);
  max-width: 80%;
  min-width: 200px;
}
.center-modal.transparent {
  background: transparent;
}
.center-modal.colored {
  @apply bg-neutral-200;
}
.center-modal.no-padding {
  padding: 0;
}
.center-modal-close-btn {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
ul.info li {
  margin-top: 0.25rem;
}
.screen.hide-modal .center-modal {
  display: none;
}
.modal-section {
  position: relative;
  margin: auto;
  background: var(--white);
  border-radius: var(--large-radius);
  padding: 1.5rem;
}
.modal-section.transparent {
  background: transparent;
}
.hide-scroll {
  overflow-y: scroll; 
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.scroll-shadow-bottom {
  box-shadow: 0 -0.5rem 0.5rem -0.5rem rgba(0,0,0,0.6) inset;
}
.scroll-shadow-top {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0,0,0,0.6) inset;
}
.scroll-shadow-both {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0,0,0,0.6) inset, 0 -0.5rem 0.5rem -0.5rem rgba(0,0,0,0.6) inset;
}
p.error {
  border-radius: var(--radius);
  padding: 0.5rem 1rem;
  @apply bg-red-200 font-semibold text-red-800
}
.bg-container {
  overflow: hidden;
}
.screen {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(2px);
  z-index: 100;
}
.screen.no-shade {
  background: transparent;
}
.btn {
  height: 2rem;
  background: var(--black);
  color: var(--white-text);
  font-weight: 500;
  padding: 0 1.5rem;
  border-radius: var(--radius);
  border: none;
  width: fit-content;
  min-width: 120px;
  cursor: pointer;
  transform: none;
  transition: 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.btn.btn-border {
  background: transparent;
  border: 2px solid var(--black);
  color: var(--black);
}
.btn-circle {
  height: 2rem;
  width: 2rem;
  background: var(--black);
  color: var(--white-text);
  font-weight: 500;
  border-radius: 50%;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  cursor: pointer;
  transform: none;
  transition: 0.2s;
}
.btn-free-width {
  min-width: 1rem;
}
.btn-small {
  min-width: auto;
  padding: 0 0.5rem;
}
.btn-circle.small {
  height: 1.5rem;
  width: 1.5rem;
}
.btn-darkgray {
  background: var(--darkgray);
}
.btn-red {
  @apply bg-red-800;
}
.btn-white {
  background: var(--white);
  color: var(--black);
}

.badge {
  background: var(--white);
  color: var(--black);
  border-radius: var(--mini-radius);
  padding: 0 1em;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}
.just-showing {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  transition: 1s;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 1.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--large-radius);
  overflow: hidden;
  box-shadow: var(--subtle-inner-shadow);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  @apply bg-neutral-300;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0.25rem;
  top: 0.125rem;
  border-radius: 50%;
  background-color: var(--white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: var(--subtle-outer-shadow);
}

input:checked + .slider {
  @apply bg-neutral-500;
  box-shadow: var(--subtle-inner-shadow);
}

input:focus + .slider {
  box-shadow: var(--subtle-inner-shadow);
}

input:checked + .slider:before {
  -webkit-transform: translateX(2.25rem);
  -ms-transform: translateX(2.25rem);
  transform: translateX(2.25rem);
}

.collapse {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
  transform-origin: 0 0;
  /* transform: scaleY(0%);
  transform-origin: top; */
}
.collapse.show {
  height: fit-content;
  animation: animate-expand 0.3s 1;
}
.collapse.collapsing {
  height: 0;
  animation: animate-collapse 0.3s 1;
}

@keyframes animate-collapse {
  0% {
    height: fit-content;
    transform: scaleY(100%);
    transform-origin: top;
  }
  100% {
    height: 0;
    transform: scaleY(0%);
    transform-origin: top;
  }
}
@keyframes animate-expand {
  0% {
    height: 0;
    transform: scaleY(0%);
    transform-origin: top;
  }
  100% {
    height: fit-content;
    transform: scaleY(100%);
    transform-origin: top;
  }
}

.input-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100% !important;
  height: 1rem;
  border-radius: var(--radius);
  border: none;
  outline: none;
  box-shadow: 0 0.15rem 0.5rem rgba(0,0,0,0.4) inset;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  @apply bg-slate-200;
}

.input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; 
  cursor: pointer;
  box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.4);
  background: var(--darkgray);
}

.input-slider::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background: var(--darkgray);
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.4);
  border-radius: 50%; 
  cursor: pointer;
}

.flip-180 {
  transform: rotate(180deg) !important;
}

.thin-border {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  align-self: center;
  height: 66%;
}

@-webkit-keyframes color-wave {
  0% {
      background-position:10% 0%
  }
  50% {
      background-position:91% 100%
  }
  100% {
      background-position:10% 0%
  }
}
@-moz-keyframes color-wave {
  0% {
      background-position:10% 0%
  }
  50% {
      background-position:91% 100%
  }
  100% {
      background-position:10% 0%
  }
}
@keyframes color-wave {
  0% {
      background-position:10% 0%
  }
  50% {
      background-position:91% 100%
  }
  100% {
      background-position:10% 0%
  }
}

.notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background: var(--black);
  color: var(--white-text);
  padding: 1rem;
  border-radius: var(--large-radius) var(--large-radius) 0 0;
  transition: 0.5s;
  overflow: hidden;
}
.notification.hide {
  bottom: -10rem;
}
.notification p {
  font-size: 10px;
}
.notification .p-emphasize {
  font-size: 10px;
}

.app-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: 0.2s;
}
.app-loading.hide {
  opacity: 0;
}

.form-inline-label {
  white-space: nowrap;
}

#goog-gt-tt {
  position: fixed;
  top: unset !important;
  bottom: 0;
  left: 0 !important;
  width: 100%;
  height: fit-content;
  border-radius: var(--radius) var(--radius) 0 0;
  font-family: 'Poppins', sans-serif;
  display: none !important;
}
.h5-notranslate {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}

.loading {
  opacity: 0;
}

iframe {
  display: block;
  transform: translateY(-200%);
}
#goog-gt- {
  display: none !important;
}