.phrase-header-emoji-1 {
    position: relative;
    left: 0;
    opacity: 1;
    transition: 0.5s;
    animation: speed 0.6s 1;
}
.phrase-header-emoji-2 {
    position: relative;
    left: 0;
    opacity: 1;
    animation: speed-2 0.6s 1;
    animation-delay: 0.3s;
    transition: 0.5s;
}
.phrase-header-emoji-3 {
    position: relative;
    left: 0;
    opacity: 0;
    animation: speed 0.6s 1;
    animation-delay: 0s;
    transition: 0.1s;
}

@keyframes speed {
    0% {
        opacity: 0;
        left: 0rem;
    }
    70% {
        opacity: 100%;
        left: 0.5rem;
    }
    100% {
        opacity: 0%;
        left: 1rem;
    }
}
@keyframes speed-2 {
    0% {
        opacity: 0;
        left: 0;
    }
    70% {
        opacity: 100%;
        left: 0.75rem;
    }
    100% {
        opacity: 0%;
        left: 1.25rem;
    }
}

.phrase-modal {
    background: transparent;
    padding: 0;
}
