@import url('https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&family=Homemade+Apple&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
  scroll-behavior: smooth;
  background: var(--white);
}

body {
  margin: 0;
  height: 100vh;
  max-height: 100svh;
  font-family: 'Poppins', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
}
#root {
  height: 100vh;
  max-height: 100svh;
  background: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select {
  background: var(--white);
  border-radius: var(--radius);
  font-size: 0.8rem;
  height: 2rem;
  padding: 0 0.5rem;
  border: 1px solid var(--midgray);
  transition: 0.1s;
  box-sizing: border-box;
}
input.error {
  @apply bg-red-300 text-red-900;
}
input[type=checkbox] {
  cursor: pointer;
}

textarea {
  background: var(--white);
  border-radius: var(--radius);
  font-size: 0.8rem;
  min-height: 3rem;
  padding: 0.5rem;
  border: 1px solid var(--midgray);
  transition: 0.1s;
  box-sizing: border-box;
}
button {
  height: 2rem;
  background: var(--black);
  color: var(--white-text);
  font-weight: 500;
  padding: 0 1.5rem;
  border-radius: var(--radius);
  border: none;
  width: fit-content;
  min-width: 120px;
  cursor: pointer;
  transform: none;
  transition: 0.2s;
}
button:not(.special-btn):hover {
  background: var(--darkgray);
}
button.btn-border {
  background: transparent;
  border: 2px solid var(--black);
  color: var(--black);
}
button.btn-border:hover  {
  background: var(--black);
  color: var(--white);
}
button.disabled-btn {
  background: var(--gray);
}
btn-small {
  padding: 0 1rem;
}

h1 {
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}
h2 {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}
h3 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}
h4 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}
h5 {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1em;
  @apply subpixel-antialiased;
}
h5.thin {
  font-weight: 200;
}
p {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.25em;
  @apply subpixel-antialiased;
}
.p-title {
  font-size: 1rem;
  font-weight: 400;
}
.p-emphasize {
  font-weight: 600;
  letter-spacing: -0.025em;
}
.p-small {
  font-weight: 400;
  font-size: 0.6rem;
}
i {
  font-family: 'Dawning of a New Day';
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  @apply subpixel-antialiased;
}
span {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.25em;
  @apply subpixel-antialiased;
}

:root {
  --black: #0f172a;
  --darkgray: #334155;
  --gray:  #64748b;
  --midgray: #cbd5e1;
  --lightgray: #e2e8f0;
  --white: #f8fafc;
  --white-text: #f8fafcdd;
  --large-radius: 1rem;
  --radius: 0.5rem;
  --mini-radius: 0.25rem;
  --outer-shadow: 0.5rem 0.5rem 0.5rem rgba(0,0,0,0.4);
  --alt-outer-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.4);
  --outer-shadow-spread: 1rem 1rem 3rem -1rem rgba(0,0,0,0.6);
  --inner-shadow: 0.3rem 0.3rem 0.3rem rgba(0,0,0,0.4) inset;
  --outer-glow: -0.5rem -0.5rem 0.5rem rgba(255,255,255);
  --outer-glow-spread: -1rem -1rem 3rem rgba(255,255,255);
  --inner-glow: -0.5rem -0.5rem 0.5rem rgba(255,255,255) inset;
  --subtle-outer-shadow: 0 0.25rem 0.25rem rgba(0,0,0,0.2);
  --subtle-inner-shadow: 0 0.25rem 0.25rem rgba(0,0,0,0.2) inset;
  --ch: 1vh;
}


.screen.z-250 {
  z-index: 250;
}
.screen.z-240 {
  z-index: 240;
}
.screen.z-230 {
  z-index: 230;
}
.screen.z-220 {
  z-index: 220;
}
.screen.z-210 {
  z-index: 210;
}
.screen.z-200 {
  z-index: 200;
}
.screen.z-190 {
  z-index: 190;
}
.screen.z-180 {
  z-index: 180;
}
.screen.z-170 {
  z-index: 170;
}
.screen.z-160 {
  z-index: 160;
}
.screen.z-150 {
  z-index: 150;
}
.screen.z-140 {
  z-index: 140;
}
.screen.z-130 {
  z-index: 130;
}
.screen.z-120 {
  z-index: 120;
}
.screen.z-110 {
  z-index: 110;
}
.screen.z-100 {
  z-index: 100;
}
.screen.z-90 {
  z-index: 90;
}
.screen.z-80 {
  z-index: 80;
}
.screen.z-70 {
  z-index: 70;
}
.screen.z-60 {
  z-index: 60;
}

.googleTranslateElement {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, -200%);
  background: var(--white);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.5s;
}
.googleTranslateElement.show {
  transform: translate(-50%, 0);
  box-shadow: var(--alt-outer-shadow);
}
.googleTranslateElement div {
  color: var(--black);
}
.googleTranslateElement > div {
  color: transparent;
}

.googleTranslateElement span {
  display: inline-block;
  width: fit-content;
  line-height: 1em;
  transform: translateY(0.4rem);
}
.googleTranslateElement a {
  display: flex;
}
.googleTranslateElement img {
  height: 1rem;
  width: auto;
}
iframe.goog-te-banner-frame {
  transform: translateY(-200%);
}